import { StyleSheet } from 'react-native'

export const footerStyles = StyleSheet.create({
  container: {
    backgroundColor: '#0077b4',
    bottom: 0,
    width: '100%',
    marginTop: 0,
  },
  footerTop: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: 100,
    paddingHorizontal: 20,
  },
  logo: {
    width: 191,
    height: 60,
    marginRight: 10,
  },
  logoRanked: {
    width: 60,
    height: 60,
    marginHorizontal: 20,
  },
  footerLinks: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  link: {
    color: 'white',
    fontSize: 16,
    marginBottom: 5,
    paddingLeft: 20,
  },
  // bottomFooter: {
  //   backgroundColor: '#333',
  //   padding: 10,
  // },
  // footerText: {
  //   color: 'white',
  //   fontSize: 12,
  //   textAlign: 'center',
  // },
  bottomFooter: {
    backgroundColor: '#0e3c83',
    color: 'white',
    fontSize: 12,
    textAlign: 'right',
    padding: 10,
  },
})
