import React, { useState } from 'react'
import { View, Image, Text } from 'react-native'
import headerStyles from '../components/Header/header.styles'
import { TouchableOpacity } from 'react-native-web'
import { withRouter } from 'react-router-dom'
import { NavigationWebStyles as navStyle } from './NavigationWebStyles'

const HeaderWeb = ({ history, viewNavigation }) => {
    const [isHovered, setIsHovered] = useState(false)

    const handleLogout = () => {
        localStorage.removeItem('token')
        history.push('/iniciar-sesion')
        return
    }

    const handleMouseEnter = () => {
        setIsHovered(true)
    }

    const handleMouseLeave = () => {
        setIsHovered(false)
    }
    const handleNavigation = () => {
        viewNavigation()
    }

    const [activeLink, setActiveLink] = useState('')
    
    const onLinkClick = (link) => {
        setActiveLink(link)
        link.startsWith('http') ? window.open(link, '_blank') : history.push(link)
    }

    return (<View>
    <View style={headerStyles.headerContainer}>
        <View style={headerStyles.leftContainer}>
            <View style={headerStyles.leftContainer} >
                <Image source={require('../assets/images/logo_grupodefensa_full.png')} style={headerStyles.logo} />
            </View>

            <View style={[headerStyles.centerContainer, { backgroundColor: '' }]} >
            <TouchableOpacity
                    onPress={() => onLinkClick('/home')}
                    style={ activeLink === '/home' ? navStyle.buttonActive : navStyle.button } >
                    <Text style={ activeLink === '/home' ? navStyle.buttonActiveText : navStyle.buttonText } > Inicio </Text>
                </TouchableOpacity>

                <TouchableOpacity
                    onPress={() => onLinkClick('/mis-movimientos')}
                    style={ activeLink === '/mis-movimientos' ? navStyle.buttonActive : navStyle.button } >
                    <Text style={ activeLink === '/mis-movimientos' ? navStyle.buttonActiveText : navStyle.buttonText } > Mis movimientos </Text>
                </TouchableOpacity>

                <TouchableOpacity 
                    onPress={() => onLinkClick('https://agenda.grupodefensa.cl/')} 
                    style={ activeLink === '/agendar-hora' ? navStyle.buttonActive : navStyle.button } >
                    <Text style={ activeLink === '/agendar-hora' ? navStyle.buttonActiveText : navStyle.buttonText } >Agendar hora </Text>
                </TouchableOpacity>

                <TouchableOpacity
                    onPress={() => onLinkClick('/mis-datos')}
                    style={ activeLink === '/mis-datos' ? navStyle.buttonActive : navStyle.button } >
                    <Text style={ activeLink === '/mis-datos' ? navStyle.buttonActiveText : navStyle.buttonText } > Mis datos </Text>
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => onLinkClick('/estado-de-cuenta')}
                    style={ activeLink === '/estado-de-cuenta' ? navStyle.buttonActive : navStyle.button } >
                    <Text style={ activeLink === '/estado-de-cuenta' ? navStyle.buttonActiveText : navStyle.buttonText } > Estado de cuenta </Text>
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => onLinkClick('/club-de-beneficios')}
                    style={ activeLink === '/club-de-beneficios' ? navStyle.buttonActive : navStyle.button } >
                    <Text style={ activeLink === '/club-de-beneficios' ? navStyle.buttonActiveText : navStyle.buttonText } > Beneficios </Text>
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => onLinkClick('https://pagos.defensadeudores.cl')} 
                    style={ activeLink === '/porta-pagos' ? navStyle.buttonActive : navStyle.button } >
                    <Text  style={ activeLink === '/porta-pagos' ? navStyle.buttonActiveText : navStyle.buttonText }>Portal de pagos</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => onLinkClick('https://api.whatsapp.com/send/?phone=56956079661&type=phone_number&app_absent=0')}
                    style={ activeLink === '/wa.me' ? navStyle.buttonActive : navStyle.button } >
                    <Text  style={ activeLink === '/wa.me' ? navStyle.buttonActiveText : navStyle.buttonText }>Hablemos por WhatsApp</Text>
                </TouchableOpacity>
            </View>
          
            <View style={headerStyles.rightContainer} >
                <View style={headerStyles.socialButtonsContainer}>
                    <TouchableOpacity style={headerStyles.button} onPress={handleLogout} >
                        <Text style={headerStyles.logout}>Salir</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    </View>
    </View>)
}

export default withRouter(HeaderWeb)
/*

import React, { useEffect, useState } from 'react'
import { Text, View, TouchableOpacity, Image } from 'react-native'
import {NavigationWebStyles }  from './NavigationWebStyles'
import { navigationStyles } from '../styles/navigation.styles'
import { withRouter } from 'react-router-dom'

const HeaderWeb = ({ history }) => {
    const [activeLink, setActiveLink] = useState('')
    
    const onLinkClick = (link) => {
        setActiveLink(link)
        link.startsWith('http') ? window.open(link, '_blank') : history.push(link)
    }

  return (
    <>
        <View style={NavigationWebStyles.navigationContainer}>
        <Text style={navigationStyles.menuNav}> MENU </Text>
        

       xxxxxxxxxxxxxxxxxxxxxxxxxxxx
        
        </View>

    </>
  )
}

export default withRouter(HeaderWeb)
*/
