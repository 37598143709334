import React, { useState, useEffect } from 'react'
import { View, Button, TouchableOpacity, Text, Image, Modal, ScrollView, TextInput } from 'react-native'
import { Formik, Field, ErrorMessage } from 'formik'
import { withRouter } from 'react-router-dom' // Importa withRouter
import { resetPasswordStyles } from './reset-password.styles'
import { queryLogin } from '../../services/axios'
import { styles as stylesModal } from '../../styles/modalMovements.styles'

const ResetPassword = ({ history }) => {
  const [error, setError] = useState('')
  const [modalVisible, setModalVisible] = useState(false)

  const handleResetPassword = async (values) => {
    try {
      values.rut = values.rut.replaceAll(".","")
      const response = await queryLogin('/api/Login/RecoverPassword', values)
      const { token, data } = response.data;
      localStorage.setItem('token', token)
      localStorage.setItem('optionsrecover', JSON.stringify({options:data}))
      
      history.push('/olvide-mi-contrasena-options')

    } catch (error) {
      console.log(error)
      setError('Clave o usuario incorrecto') // Establece el mensaje de error
    }
  }
  useEffect(()=>{
    setModalVisible(true)
  },[])

  const CustomInputRut = ({ field, form, ...props }) => (
    <TextInput
      style={resetPasswordStyles.input}
      onChangeText={value => form.setFieldValue(field.name, handleRutChange(value))}
      onBlur={() => form.setFieldTouched(field.name)}
      value={field.value}
      {...props}
    />
  );
  const handleRutChange = (texto) => {
    texto = texto.replaceAll("-","")
    return texto.slice(0, -1) + '-' + texto.slice(-1);
  };

  return (<>
    <Formik initialValues={{ rut: '' }} onSubmit={handleResetPassword}>
      {({ handleSubmit }) => (
        <View style={resetPasswordStyles.container}>
          <View style={[resetPasswordStyles.banner]}></View>
          <View style={resetPasswordStyles.form}>
            <View style={{ alignItems: 'center' }}>
              <Image
                style={resetPasswordStyles.logoImage}
                source={require('../../assets/images/logo_grupodefensa_full.png')}
              />
            </View>

            <Text
              style={[
                !error
                  ? [
                      resetPasswordStyles.label.success,
                      resetPasswordStyles.label,
                    ]
                  : [
                      resetPasswordStyles.label.error,
                      resetPasswordStyles.label,
                    ],
              ]}
            >
              RUT 
            </Text>
            <Field
              style={resetPasswordStyles.input}
              component={CustomInputRut}
              name="rut"
              placeholder="RUT"
            />
            <ErrorMessage
              name="rut"
              component={Text}
              style={resetPasswordStyles.errorText}
            />
            {error ? (
              <Text style={resetPasswordStyles.errorText}>{error}</Text> // Muestra el mensaje de error si existe
            ) : null}
            <Button title="Recuperar contraseña" onPress={handleSubmit} />
            <TouchableOpacity onPress={() => history.push('/iniciar-sesion')}>
              <Text style={resetPasswordStyles.forgotPasswordText}>
                Ir a iniciar sesión
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      )}
    </Formik>
    <Modal
        animationType="slide"
        transparent={true}
        visible={/*modalVisible*/ false}
        onRequestClose={() => {
          setModalVisible(!modalVisible)
        }}
      >
        <View style={stylesModal.centeredView}>
          <View style={ { ...{width:'75%', maxWidth: 600}, ...stylesModal.modalView }}>
            <ScrollView style={{maxHeight: "600px"}}>
              <Text style={{fontWeight:'bold', fontSize:18, marginBottom: 10, textAlign:'center', color: '#838383'}}> Bienvenido a tu nueva sucursal virtual </Text>
              <Text style={{fontWeight:'bold', fontSize:15, textAlign:'center', color: '#000',marginBottom: 15}}>Para iniciar por primera siga los siguientes pasos: </Text>
              <Text style={{fontWeight:'bold', fontSize:15, color: '#000', textAlign:'justify', marginBottom: 5}}>1- Ingrese su Rut y presion RECUPERAR CONTRASEÑA </Text>
              <Text style={{fontWeight:'italic', fontSize:15, color: '#838383', textAlign:'justify', marginBottom: 5}}>2- Seleccione su correo o telefono registrado para recibir un código de 6 números.</Text>
              <Text style={{fontWeight:'italic', fontSize:15, color: '#838383', textAlign:'justify', marginBottom: 5}}>3- Ingrese este código de 6 números para crear su nueva clave.</Text>
              <Text style={{fontWeight:'italic', fontSize:15, color: '#838383', textAlign:'justify', marginBottom: 5}}>4- Su clave debe cumplir las siguientes características:</Text>
              <Text style={{fontWeight:'italic', fontSize:15, color: '#838383', textAlign:'justify', marginBottom: 5, paddingLeft: 20}}>a- Mínimo 6 caracteres</Text>
              <Text style={{fontWeight:'italic', fontSize:15, color: '#838383', textAlign:'justify', marginBottom: 5, paddingLeft: 20}}>b- Al menos una letra mayúscula</Text>
              <Text style={{fontWeight:'italic', fontSize:15, color: '#838383', textAlign:'justify', marginBottom: 5, paddingLeft: 20}}>c- Al menos un número.</Text>
             
            </ScrollView>
            <Button style={{marginTop: '15px'}} title="Cerrar" onPress={() => setModalVisible(false)} />
          </View>
        </View>
      </Modal>
    </>
  )
}

export default withRouter(ResetPassword) // Envuelve el componente Login con withRouter
