/*
import React, { forwardRef, useRef } from 'react';
import { TextInput, View, Text } from 'react-native'
import { customTextInputStyles } from '../styles/customTextInput.styles'

const DateInput = forwardRef((onChangeText, valueFecha, props, ref) => {
  const label = 'Fecha'

  return (
    <View>
      <Text style={customTextInputStyles.label} aria-label={label} nativeID={label} >
        {label} <span style={customTextInputStyles.span}>*</span>
      </Text>
      <TextInput
        ref={ref}
        style={customTextInputStyles.input}
        {...props}
        onChangeText={()=>onChangeText(valueFecha)}
        value={valueFecha}
      />
    </View>
  )
})

export default DateInput
*/

// 2 last
import React, { useState, useEffect } from 'react'
import { TextInput, View, Text } from 'react-native'
import { customTextInputStyles } from '../styles/customTextInput.styles'

const DateInput = ({Fdate, Flabel, ...props }) => {
  const label = Flabel
  return (
    <View>
      <Text style={customTextInputStyles.label} aria-label={label} nativeID={label} >
        {label} <span style={customTextInputStyles.span}>*</span>
      </Text>
      <TextInput
        style={customTextInputStyles.input}
        {...props}
        value={Fdate}
      />
    </View>
  )
}

export default DateInput


// 111
/*
import React, { useState, useEffect } from 'react'
import { TextInput, View, Text } from 'react-native'
import { customTextInputStyles } from '../styles/customTextInput.styles'

const DateInput = ({ onChangeText, ...props }) => {
  const [date, setDate] = useState('')
  const label = 'Fecha'

  const handleTextChange = (text) => {
    let trimmedText = text.replace(/-/g, '')
    if (trimmedText.length > 8) {
      // prevent from adding more digits if length is already 8
      trimmedText = trimmedText.substring(0, 8)
    }

    let formattedText = trimmedText
    if (trimmedText.length > 4) {
      formattedText = `${trimmedText.slice(0, 4)}-${trimmedText.slice(
        4,
        6
      )}-${trimmedText.slice(6)}`
    } else if (trimmedText.length > 2) {
      formattedText = `${trimmedText.slice(0, 4)}-${trimmedText.slice(4)}`
    }

    setDate(formattedText)
    // Send the formatted date to parent component
    onChangeText(formattedText)
  }

  return (
    <View>
      <Text
        style={customTextInputStyles.label}
        aria-label={label}
        nativeID={label}
      >
        {label} <span style={customTextInputStyles.span}>*</span>
      </Text>
      <TextInput
        style={customTextInputStyles.input}
        {...props}
        onChangeText={handleTextChange}
        value={date}
      />
    </View>
  )
}

export default DateInput
*/