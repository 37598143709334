import React from 'react';
import { TouchableOpacity, Text, ActivityIndicator, StyleSheet } from 'react-native';

class LoadingButton extends React.Component {
  render() {
    const { onPress, isLoading, title, disabledColor, activeColor, ...otherProps } = this.props;

    return (
      <TouchableOpacity 
        style={[
          styles.button, 
          { backgroundColor: isLoading ? disabledColor : activeColor }
        ]}
        onPress={onPress} 
        disabled={isLoading} 
        {...otherProps}
      >
        {isLoading ? (
          <ActivityIndicator size="small" color="#FFF" />
        ) : (
          <Text style={styles.buttonText}>{title}</Text>
        )}
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    color: '#FFF',
    fontSize: 16,
  },
});

export default LoadingButton;
