import { StyleSheet } from 'react-native'

export const movementsStyles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: 'black',
    padding: 20,
    textAlign: 'center',
  },
  headerCell: {
    flex: 1,
    fontWeight: 'bold',
    paddingVertical: 5,
    width: 50,
    color: 'rgb(0, 118, 180)',
  },
  cell: {
    flex: 1,
    paddingVertical: 5,
    marginHorizontal: 5,
    paid: {
      color: 'green',
      fontWeight: 'bold',
    },
    unpaid: {
      backgroundColor: 'red',
      color: 'white',
      borderRadius: '15',
    },
  },
})
