import React, { useState, useEffect} from 'react'
import { View, Button, TouchableOpacity, Text, Image, Modal, ScrollView } from 'react-native'
import { Formik, Field, ErrorMessage } from 'formik'
import { withRouter } from 'react-router-dom' // Importa withRouter
import { resetPasswordStyles } from './reset-password.styles'
import { resetPasswordOption } from '../../services/axios'
import { styles as stylesModal } from '../../styles/modalMovements.styles'

const DigitResetPassword = ({ history }) => {
  const [error, setError] = useState('')
  const [modalVisible, setModalVisible] = useState(false)

  const handleResetPassword = async (values) => {
    try {
      const response = await resetPasswordOption('/SucursalVirtual/VerificarCodigo/'+values.code, {}, localStorage.getItem('token'))
      const { token } = response.data;
      localStorage.setItem('token', token)
      history.push('/olvide-mi-contrasena-new')

    } catch (error) {
      console.log(error)
      setError('Clave incorrecta') // Establece el mensaje de error
    }
  }
  useEffect(()=>{
    setModalVisible(true)
  },[])

  return (<>
    <Modal
      animationType="slide"
      transparent={true}
      visible={/* modalVisible */false}
      onRequestClose={() => {
        setModalVisible(!modalVisible)
      }}
    >
      <View style={stylesModal.centeredView}>
        <View style={ { ...{width:'75%', maxWidth: 600}, ...stylesModal.modalView }}>
          <ScrollView style={{maxHeight: "600px"}}>
            <Text style={{fontWeight:'bold', fontSize:18, marginBottom: 10, textAlign:'center', color: '#838383'}}> Bienvenido a tu nueva sucursal virtual </Text>
            <Text style={{fontWeight:'bold', fontSize:15, textAlign:'center', color: '#000',marginBottom: 15}}>Para iniciar por primera siga los siguientes pasos: </Text>
            <Text style={{fontWeight:'italic', fontSize:15, color: '#838383', textAlign:'justify', marginBottom: 5}}>1- Ingrese su Rut y presion RECUPERAR CONTRASEÑA </Text>
            <Text style={{fontWeight:'italic', fontSize:15, color: '#838383', textAlign:'justify', marginBottom: 5}}>2- Seleccione su correo o telefono registrado para recibir un código de 6 números.</Text>
            <Text style={{fontWeight:'bold', fontSize:15, color: '#000', textAlign:'justify', marginBottom: 5}}>3- Ingrese el código de 6 números para crear su nueva clave.</Text>
            <Text style={{fontWeight:'bold', fontSize:15, color: '#000', textAlign:'center', marginBottom: 5}}>El código de 6 números debe llegar a su correo o telefono segun lo seleccionado.</Text>
            <Text style={{fontWeight:'italic', fontSize:15, color: '#838383', textAlign:'justify', marginBottom: 5}}>4- Su clave debe cumplir las siguientes características:</Text>
            <Text style={{fontWeight:'italic', fontSize:15, color: '#838383', textAlign:'justify', marginBottom: 5, paddingLeft: 20}}>a- Mínimo 6 caracteres</Text>
            <Text style={{fontWeight:'italic', fontSize:15, color: '#838383', textAlign:'justify', marginBottom: 5, paddingLeft: 20}}>b- Al menos una letra mayúscula</Text>
            <Text style={{fontWeight:'italic', fontSize:15, color: '#838383', textAlign:'justify', marginBottom: 5, paddingLeft: 20}}>c- Al menos un número.</Text>
            
          </ScrollView>
          <Button style={{marginTop: '15px'}} title="Cerrar" onPress={() => setModalVisible(false)} />
        </View>
      </View>
    </Modal>
    <Formik initialValues={{ rut: '' }} onSubmit={handleResetPassword}>
      {({ handleSubmit }) => (
        <View style={resetPasswordStyles.container}>
          <View style={[resetPasswordStyles.banner]}></View>
          <View style={resetPasswordStyles.form}>
            <View style={{ alignItems: 'center' }}>
              <Image
                style={resetPasswordStyles.logoImage}
                source={require('../../assets/images/logo_grupodefensa_full.png')}
              />
            </View>
            <Text  style={{fontSize: 20, marginBottom: 15, textAlign:'center', fontWeight:'bold', color: '#838383' }}>Digitar codigo de confimacion</Text>

            <Text style={{marginBottom:10, textAlign:'center', fontWeight: 'bold', fontSize: 16 }}>
                Se ha enviado el codigo de autorizacion: <br/>(si es correo revisa tu spam)
            </Text>

            <Text
              style={[
                !error
                  ? [
                      resetPasswordStyles.label.success,
                      resetPasswordStyles.label,
                    ]
                  : [
                      resetPasswordStyles.label.error,
                      resetPasswordStyles.label,
                    ],
              ]}
            >
              CLAVE DE AUTORIZACION
            </Text>


            <Field
              style={resetPasswordStyles.input}
              name="code"
              placeholder="Codigo de autorización"
            />
            <ErrorMessage
              name="code"
              component={Text}
              style={resetPasswordStyles.errorText}
            />
            {error ? (
              <Text style={resetPasswordStyles.errorText}>{error}</Text> // Muestra el mensaje de error si existe
            ) : null}
            <Button title="Enviar Autorizacion" onPress={handleSubmit} />
            <TouchableOpacity onPress={() => history.push('/iniciar-sesion')}>
              <Text style={resetPasswordStyles.forgotPasswordText}>
                Ir a iniciar sesión
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      )}
    </Formik>
    </>
  )
}

export default withRouter(DigitResetPassword) // Envuelve el componente Login con withRouter
