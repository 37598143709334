import { StyleSheet } from 'react-native'

export const benefitsCardStyles = StyleSheet.create({
  generalContainer: {
    width: '100%',
  },
  mainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingHorizontal: 20,
  },
  container: {
    flexDirection: 'row',
    backgroundColor: 'white',
    borderRadius: 10,
    marginBottom: 20,
    padding: 10,
    borderBottomColor: '1px solid red',
    alignItems: 'center',
    textAlign: 'left',
  },
  image: {
    width: 150,
    height: 150,
    borderRadius: 10,
    marginRight: 10,
  },
  contentContainer: {
    flex: 1,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    color: "#2596be"
  },
  text: {
    fontSize: 16,
    marginBottom: 10,
  },
  date: {
    fontSize: 14,
    marginBottom: 10,
  },
  terms: {
    fontSize: 14,
  },
  termsLink: {
    color: 'blue',
    textDecorationLine: 'underline',
  },
})
