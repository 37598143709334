import { StyleSheet } from 'react-native'

export const navigationStyles = StyleSheet.create({
  menuNav: {
    paddingTop: 22.5,
    paddingLeft: 18,
    paddingBottom: 20,
    color: '#adb5bd',
    fontWeight: 'bold',
    fontSize: '0.7875rem',
    textTransform: 'uppercase',
    letterSpacing: '0.2em'
    //,width: '100%' 
    /*
    margin-bottom: 0;
    padding: 1.5rem;
    letter-spacing: 0.2em;
    */
  },
  button: {
    margin: 1,
    backgroundColor: '#fcfcfc',
    padding: 10,
    paddingLeft: 20,
    //borderRadius: 15,
    textDecorationLine: 'none',
  },
  buttonText: {
    color: '#0076b4',
  },
  buttonActive: {
    margin: 1,
    backgroundColor: '#0076b4',
    padding: 10,
    paddingLeft: 20,
    //borderRadius: 15,
    textDecorationLine: 'none',
  },
  buttonActiveLink: {
    margin: 1,
    backgroundColor: '#69A62D',
    padding: 10,
    paddingLeft: 20,
    //borderRadius: 15,
    textDecorationLine: 'none',
  },
  buttonNoActiveLink: {
    margin: 1,
    backgroundColor: '#bbc5b2',
    padding: 10,
    paddingLeft: 20,
    //borderRadius: 15,
    textDecorationLine: 'none',
  },
  buttonActiveText: {
    color: 'white',
  },
  image: {
    height: 100,
    width: '200px',
    border: '1px solid ',
    overflow: 'hidden',
  },
})
