import React, { useContext, useState } from 'react'
import { View } from 'react-native'
import Contact from './src/screens/contact/Contact'
import BenefitsClub from './src/screens/benefits-club/BenefitsClub'
import CheckingAccount from './src/screens/checking-account/CheckingAccount'
import Profile from './src/screens/Profile'
import MyFiles from './src/screens/MyFiles'
import Navigation  from './src/navigation/Navigation'
import HeaderWeb  from './src/navigation/HeaderWeb'
import { generalStyles } from './src/styles/general.styles'
import Movements from './src/screens/movements/Movements'
import PaymentPortal from './src/screens/payment-portal/PaymentPortal'
import Footer from './src/components/footer/Footer'
import { Router, Switch, Route } from './src/router/index'
import Login from './src/screens/login/Login'
import Header from './src/components/Header/Header'
import Appointment from './src/screens/appointment/Appointment'
import ToastContainerComponent from './src/common/ToastContainerComponent'
import ResetPassword from './src/screens/reset-password/ResetPassword'
import OptionsResetPassword from './src/screens/reset-password/OptionsResetPassword'
import DigitResetPassword from './src/screens/reset-password/DigitResetPassword'
import UpdatePassword from './src/screens/reset-password/UpdatePassword'
import HomeView from './src/screens/home/HomeView'
import Documents from './src/screens/home/Documents'

import { HomeContext, HomeProvider } from './src/context/HomeContext'
import { Redirect } from 'react-router-dom'
import { isMobile } from './src/utils/dimensions'

const App = () => {
  const [reservation, setReservation] = useContext(HomeContext)
  const [showNavigation,setShowNavigation] = useState(isMobile())
  const NavigationMobile = Navigation

  const viewNavigation = () => {
    if(isMobile())
      setShowNavigation(!showNavigation)
  } 
  const viewNavigationB = () => {
    if(isMobile())
      setShowNavigation(false) 
  } 

  return (
    <HomeProvider>
      <Router>
        <View style={{ width: '100%' }}>
          <ToastContainerComponent />
          <Switch>
            <Route exact path="/iniciar-sesion" component={Login} />
            <Route path="/olvide-mi-contrasena" component={ResetPassword} />
            <Route
              path="/olvide-mi-contrasena-options"
              component={OptionsResetPassword}
            />
            <Route
              path="/olvide-mi-contrasena-digitar"
              component={DigitResetPassword}
            />
            <Route
              path="/olvide-mi-contrasena-new"
              component={UpdatePassword}
            />
            <View style={generalStyles.generalContainer}>
              {isMobile() ? <Header viewNavigation={()=>viewNavigation()} />: <HeaderWeb showNavigation={showNavigation}/> }
              <View style={[generalStyles.mainContainer]}>
              {isMobile() ? <NavigationMobile showNavigation={showNavigation} viewNavigationB={()=>viewNavigationB()}/> : null }
                <Switch>
                  <Route path="/mis-movimientos" component={Movements} />
                  <Route path="/portal-de-pagos" component={PaymentPortal} />
                  {/*<Route path="/agendar-hora" component={Appointment} />*/}
                  {/* <Route path="/mis-archivos" component={MyFiles} /> */}
                  <Route exact path="/mis-datos" component={Profile} />
                  <Route path="/estado-de-cuenta" component={CheckingAccount} />
                  <Route path="/club-de-beneficios" component={BenefitsClub} />
                  <Route path="/mis-documentos" component={Documents} />
                  
                  <Route path="/home" component={HomeView} />
                  {/*<Route path="/contacto" component={Contact} />*/}
                  {isMobile() ? <Redirect to="/mis-movimientos" /> : <Redirect to="/home" /> }
                </Switch>
              </View>
              <Footer />
            </View>
          </Switch>
        </View>
      </Router>
    </HomeProvider>
  )
}

export default App
