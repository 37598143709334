import { toast } from 'react-toastify'

/**
 * Muestra una notificación utilizando la librería react-toastify.
 *
 * @param {string} type - El tipo de notificación: 'success', 'error', 'warning' o 'info'.
 * @param {string} msg - El mensaje de la notificación.
 * @param {Object} [onClose={}] - Opciones adicionales para el cierre de la notificación.
 */
export const notify = (type, msg, onClose = {}) => {
  switch (type) {
    case 'success':
      toast.success(msg, { onClose })
      break
    case 'error':
      toast.error(msg)
      break
    case 'warning':
      toast.warning(msg, { onClose })
      break
    case 'info':
      toast.info(msg, { onClose })
      break
    default:
      toast(msg, { onClose })
  }
}
