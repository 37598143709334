import { createContext, useState } from 'react'

const HomeContext = createContext([{}, () => {}])

const HomeProvider = (props) => {
  const initialReservation = JSON.parse(
    localStorage.getItem('reservation')
  ) || {
    rut: '',
    fecha: '',
    hora: '',
    medio: '',
    donde: '',
    abogado: '',
    nombreSucursal: '',
  }

  const [reservation, setReservation] = useState(initialReservation)

  return (
    <HomeContext.Provider value={[reservation, setReservation]}>
      {props.children}
    </HomeContext.Provider>
  )
}

export { HomeContext, HomeProvider }
