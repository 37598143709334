import React, { useState, useEffect } from 'react'
import { View, Button, TouchableOpacity, Text, Image, TextInput, Modal, ScrollView  } from 'react-native'
import { Formik, Field, ErrorMessage } from 'formik'
import { withRouter } from 'react-router-dom' // Importa withRouter
import { resetPasswordStyles } from './reset-password.styles'
import { loginStyles } from '../login/login.styles'
import { resetPasswordQuery } from '../../services/axios'
import { styles as stylesModal } from '../../styles/modalMovements.styles'
import { notify } from '../../utils/notify'
import isMobile from '../../utils/dimensions'

const UpdatePassword = ({ history, Profile }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [isProfile, setIsProfile] = useState(false)
  

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      history.push('/iniciar-sesion') // Ajusta la ruta de inicio de sesión según tu configuración de navegación
    }
    if(Profile!=null){
      setIsProfile(Profile)
    }
  }, [])

  const [error, setError] = useState('')

  const handleResetPassword = async (values) => {
    if(values.newPass=='' || values.repeatPass=='' ){
        setError('Las claves son obligatorias');
        return;
    }
    if(!/\d/.test(values.newPass)){
      setError('La clave debe tener por lo menos 1 numero');
      return;
    }
    if(!/[a-zA-Z]/.test(values.newPass)){
      setError('La clave debe tener por lo menos 1 numero');
      return;
    }
    if(values.newPass.length<6){
        setError('La clave debe tener por lo menos 6 caracteres');
        return;
    }
    if(values.newPass!=values.repeatPass ){
        setError('Las claves no son iguales');
        return;
    }
    
    try {
      const response = await resetPasswordQuery('/Client/UpdatePassword', values, localStorage.getItem('token'))
      if(Profile==null){
        notify('success', "Contraseña Cambiada, redirigiendo")
        setTimeout(() => {
          isMobile() ? history.push('/mis-movimientos') : history.push('/home')
        }, 2000);
      }
      else
        notify('success', "Contraseña Cambiada")
    } catch (error) {
      if(error.response.status==401){
        localStorage.clear();
        history.push('/iniciar-sesion')
      } 
      setError('Clave incorrecta') // Establece el mensaje de error
    }
  }
  const CustomInput = ({ field, form, ...props }) => (
    <TextInput
      style={loginStyles.input}
      onChangeText={value => form.setFieldValue(field.name, value)}
      onBlur={() => form.setFieldTouched(field.name)}
      value={field.value}
      {...props}
    />
  );

  useEffect(()=>{
    setModalVisible(true)
  },[])
  return (
    <>
     <Modal
      animationType="slide"
      transparent={true}
      visible={/*modalVisible*/ false}
      onRequestClose={() => {
        setModalVisible(!modalVisible)
      }}
    >
      <View style={stylesModal.centeredView}>
        <View style={ { ...{width:'75%', maxWidth: 600}, ...stylesModal.modalView }}>
          <ScrollView style={{maxHeight: "600px"}}>
            <Text style={{fontWeight:'bold', fontSize:18, marginBottom: 10, textAlign:'center', color: '#838383'}}> Bienvenido a tu nueva sucursal virtual </Text>
            <Text style={{fontWeight:'bold', fontSize:15, textAlign:'center', color: '#000',marginBottom: 15}}>Para iniciar por primera siga los siguientes pasos: </Text>
            <Text style={{fontWeight:'italic', fontSize:15, color: '#838383', textAlign:'justify', marginBottom: 5}}>1- Ingrese su Rut y presion RECUPERAR CONTRASEÑA </Text>
            <Text style={{fontWeight:'italic', fontSize:15, color: '#838383', textAlign:'justify', marginBottom: 5}}>2- Seleccione su correo o telefono registrado para recibir un código de 6 números.</Text>
            <Text style={{fontWeight:'italic', fontSize:15, color: '#838383', textAlign:'justify', marginBottom: 5}}>3- Ingrese el código de 6 números para crear su nueva clave.</Text>
            <Text style={{fontWeight:'bold', fontSize:15, color: '#000', textAlign:'justify', marginBottom: 5}}>4- Su clave debe cumplir las siguientes características:</Text>
            <Text style={{fontWeight:'bold', fontSize:15, color: '#000', textAlign:'justify', marginBottom: 5, paddingLeft: 20}}>a- Mínimo 6 caracteres</Text>
            <Text style={{fontWeight:'bold', fontSize:15, color: '#000', textAlign:'justify', marginBottom: 5, paddingLeft: 20}}>b- Al menos una letra mayúscula</Text>
            <Text style={{fontWeight:'bold', fontSize:15, color: '#000', textAlign:'justify', marginBottom: 5, paddingLeft: 20}}>c- Al menos un número.</Text>
            <Text style={{fontWeight:'italic', fontSize:15, color: '#838383', textAlign:'justify', marginBottom: 5}}>5- Una vez confirmada inicie sesión desde la pagina inicial.</Text>
            
          </ScrollView>
          <Button style={{marginTop: '15px'}} title="Cerrar" onPress={() => setModalVisible(false)} />
        </View>
      </View>
    </Modal>
    <Formik initialValues={{ newPass: '',repeatPass: ''}} onSubmit={handleResetPassword}>
      {({ handleSubmit }) => (
        <View style={resetPasswordStyles.container}>
          <View style={[resetPasswordStyles.banner]}></View>
          <View style={resetPasswordStyles.form}>
            {!Profile ?<>
              <View style={{ alignItems: 'center' }}>
                <Image
                  style={resetPasswordStyles.logoImage}
                  source={require('../../assets/images/logo_grupodefensa_full.png')}
                />
              </View> 
              <Text  style={{fontSize: 20, marginBottom: 15, textAlign:'center', fontWeight:'bold', color: '#838383' }}>Escribe tu nueva contraseña</Text>
            </>
            :  <Text  style={{fontSize: 20, marginTop:-300, marginBottom: 15, textAlign:'center', fontWeight:'bold', color: '#838383' }}>Actualiza tu contraseña</Text>}
          
            <Text
              style={[
                !error
                  ? [
                      resetPasswordStyles.label.success,
                      resetPasswordStyles.label,
                    ]
                  : [
                      resetPasswordStyles.label.error,
                      resetPasswordStyles.label,
                    ],
              ]}
            >
              NUEVA CLAVE
            </Text>
            <Field
              component={CustomInput}
              style={resetPasswordStyles.input}
              name="newPass"
              placeholder="contraseña"
              secureTextEntry={true}
              autoCapitalize="none"
            />
            <ErrorMessage
              name="newPass"
              component={Text}
              style={resetPasswordStyles.errorText}
            />
            {error ? (
              <Text style={resetPasswordStyles.errorText}>{error}</Text> // Muestra el mensaje de error si existe
            ) : null}

            <Text
              style={[
                !error
                  ? [
                      resetPasswordStyles.label.success,
                      resetPasswordStyles.label,
                    ]
                  : [
                      resetPasswordStyles.label.error,
                      resetPasswordStyles.label,
                    ],
              ]}
            >
              REPETIR CLAVE
            </Text>
            <Field
              component={CustomInput}
              style={resetPasswordStyles.input}
              name="repeatPass"
              placeholder="repetir"
              secureTextEntry={true}
              autoCapitalize="none"
            />
            <ErrorMessage
              name="repeatPass"
              component={Text}
              style={resetPasswordStyles.errorText}
            />
            {error ? (
              <Text style={resetPasswordStyles.errorText}>{error}</Text> // Muestra el mensaje de error si existe
            ) : null}

            <Button title= {Profile? "Actualizar Contraseña":"guardar nueva contraseña"} onPress={handleSubmit} />
            {Profile?
            <View style={{paddingVertical:20, marginBottom: -250}}>
              <Text style={{fontWeight:'bold', fontSize: 13, color: '#0076b4'}}>Tu nueva contraseña debe tener:</Text>
              <Text style={{fontWeight:'bold', fontSize: 14, color: '#900C3F', marginLeft:10}}>Al menos 6 caracteres</Text>
              <Text style={{fontWeight:'bold', fontSize: 14, color: '#900C3F', marginLeft:10}}>Al menos una letra mayúscula</Text>
              <Text style={{fontWeight:'bold', fontSize: 14, color: '#900C3F', marginLeft:10}}>Al menos un número</Text>
            </View>
            ://solo recuperacion
            <TouchableOpacity onPress={() => history.push('/iniciar-sesion')}>
              <Text style={resetPasswordStyles.forgotPasswordText}>
                Ir a iniciar sesión
              </Text>
            </TouchableOpacity>}
          </View>
        </View>
      )}
    </Formik>
    </>
  )
}

export default withRouter(UpdatePassword)
