import { StyleSheet } from 'react-native'

export const contactInfoButton = StyleSheet.create({
  form: {
    padding: 20,
  },
  label: {
    fontSize: 15,
    fontWeight: 'bold',
    color: '#0076b4',
    paddingLeft: 10,
  },
  span: {
    color: 'red',
  },
  button: {
    width: 200,
    backgroundColor: '#0076b4',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start', // Esto hace que el botón se alinee a la derecha del contenedor padre
    borderRadius: 5, // Añade un poco de borde redondeado
    padding: 10, // Añade un poco de padding
    margin: 10,
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 20,
  },
})
