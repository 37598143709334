import { StyleSheet } from 'react-native'
import isMobile from '../../utils/dimensions'


const webMobileStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 'center',
    backgroundImage: 'url("../../assets/images/logo_grupodefensa_full.png")',
    backgroundRepeat: 'repeat',
  },
  form: {
    backgroundColor: 'white',
    justifyContent: 'center',
    width: 350,
    height: 500,
    paddingHorizontal: 20,
    flexDirection: 'column',
    shadowColor: '#171717',
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    borderWidth: 1,
    borderColor: '#f0eded',
  },
  label: {
    fontSize: 15,
    marginBottom: 10,
    fontWeight: 'bold',
    success: {
      color: '#0076b4',
    },
    error: {
      color: 'red',
    },
  },
  logoImage: {
    width: '100%',
    height: '80%',
    alignSelf: 'center',
    /*
    width: 191,
    height: 60,
    alignSelf: 'center',
    marginBottom: 56,
    */
  },
  banner: {
    width: '100%',
    height: 150,
    marginBottom: 20,
    backgroundColor: '#0076b4',
    position: 'absolute',
    top: 0,
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 25,
    paddingLeft: 10,
  },
  errorInput: {
    borderColor: 'red',
    borderWidth: 1,
  },
  errorText: {
    color: 'red',
  },
  title: {
    fontSize: 30,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  forgotPasswordText: {
    marginTop: 10,
    color: '#69A62D',
    textAlign: 'right',
    fontWeight: 'bold',
    marginTop: 20,
  },
  imageContainer: {
    alignItems: 'center',
    marginBottom: 15,
  },
  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.2)', // Puedes ajustar el color y la opacidad
  }
})


const webStyles = StyleSheet.create({
  container: {
    padding: 10,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'end',
    backgroundImage: 'url("../../assets/images/logo_grupodefensa_full.png")',
    backgroundRepeat: 'repeat',
  },
  
  form: {
    borderRadius: 10,
    backgroundColor: 'white',
    justifyContent: 'center',
    width: '35%',
    height: '100%',
    paddingHorizontal: 30,
    flexDirection: 'column',
    shadowColor: '#171717',
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    borderWidth: 1,
    borderColor: '#f0eded',
  },

  label: {
    fontSize: 15,
    marginBottom: 10,
    fontWeight: 'bold',
    success: {
      color: '#0076b4',
    },
    error: {
      color: 'red',
    },
  },
  logoImage: {
    width: '90%',
    height: '90%',
    alignSelf: 'center'
  },
  banner: {
    width: '100%',
    height: 150,
    marginBottom: 20,
    backgroundColor: '#0076b4',
    position: 'absolute',
    top: 0,
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 25,
    paddingLeft: 10,
  },
  errorInput: {
    borderColor: 'red',
    borderWidth: 1,
  },
  errorText: {
    color: 'red',
  },
  title: {
    fontSize: 30,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  forgotPasswordText: {
    marginTop: 10,
    color: '#69A62D',
    textAlign: 'right',
    fontWeight: 'bold',
    marginTop: 20,
  },
  imageContainer: {
    alignItems: 'center',
    marginBottom: 15,
  },
  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.2)', // Puedes ajustar el color y la opacidad
  }
})



export const loginStyles = isMobile() ? webMobileStyles: webStyles