import React, { useEffect, useState } from 'react'
import { Text, View, TouchableOpacity, StyleSheet } from 'react-native'
import HeaderPageTitle from '../../common/HeaderPageTitle'
import { generalStyles } from '../../styles/general.styles'
import { checkingStyles } from './checking.styles'
import { cuotasClienteQuery } from '../../services/axios'
import Loading from '../../common/Loading'
import { withRouter } from 'react-router-dom'
import { navigationStyles } from '../../styles/navigation.styles'
import { format } from 'money-formatter'
import {format as formatDate } from 'date-fns'
import isMobile from '../../utils/dimensions'

const CheckingAccount = ({ history }) => {
  const [data, setData] = useState([])
  const [dataFull, setDataFull] = useState([])
  const [buttonOn, setButtonOn] = useState(0)
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      history.push('/iniciar-sesion') // Ajusta la ruta de inicio de sesión según tu configuración de navegación
    }
  }, [])

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const response = await cuotasClienteQuery(
          '/Client/CuotasCliente',
          localStorage.getItem('token')
        )
        console.log(response.data)
        setDataFull(response.data)
        setData(response.data[0])
        setLoading(false)
      } catch (error) {
        if(error.response.status==401){
          localStorage.removeItem('token')
          history.push('/iniciar-sesion')
        }else{
          notify('error', error)
          setLoading(false)
        }
      }
    }
    getData()
  }, [])

  const showEmpresa = (index) =>{
    setData(dataFull[index])
    setButtonOn(index)
  }

  useEffect(()=>{
    //console.log(data)
  },[data])

  const onLinkClick = (link, irportal) => {
    if(irportal) window.open(link, '_blank')
  }
  

  return (
    <View style={(isMobile() ?generalStyles.contentContainerResponse:generalStyles.contentContainer)}>
      <HeaderPageTitle title="Portal de pagos." />
      
      <View >
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start' }}>
          {dataFull.map((objetosLinea, index) => (<>
            <TouchableOpacity key={index} style={[ stylesButton.button, { backgroundColor: buttonOn==index ? 'rgb(33, 150, 243)' : 'gray' } ]} onPress={() => showEmpresa(index)} >
              <Text style={stylesButton.buttonText}>{objetosLinea[0].empresa}</Text>
            </TouchableOpacity>
          </>))}
        </View>
        <View style={[stylesButton.line]}></View>
      </View>



      <View style={checkingStyles.row}>
        <Text style={[checkingStyles.headerCell, { width: 30 }]}>N°</Text>
        {/* <Text style={[checkingStyles.headerCell, { width: 100 }]}>Nombre</Text> */}
        <Text style={[checkingStyles.headerCell, { width: 100 }]}>Monto</Text>
        <Text style={[checkingStyles.headerCell, { width: 200 }]}>Vencimiento</Text>
        <Text style={[checkingStyles.headerCell, { width: 200 }]}>Detalle</Text>
        {/* 
        <Text style={[checkingStyles.headerCell, { width: 200 }]}>Estado</Text>
         */}
        <Text style={[checkingStyles.headerCell, { width: 200 }]}>Pagar</Text>
      </View>
      {loading && (<Loading />)}
      {data ? (
        data.map((item, index) => (
          <View style={checkingStyles.row} key={index}>
            <Text style={[checkingStyles.cell, { width: 30 }]}>
              {item.glosa.match(/Cuota:(\d+)/)?.[1]}
            </Text>
           {/* <Text style={[checkingStyles.cell, { width: 100 }]}>
              {item.empresa}
        </Text>*/}
            <Text style={[checkingStyles.cell, { width: 100 }]}>
              {format('CLP',item.monto)}
            </Text>
            <Text style={[checkingStyles.cell, { width: 200 }]}>
              {formatDate(new Date(item.vencimiento), 'dd/MM/yyyy')}
            </Text>
            <Text style={[checkingStyles.cell, { width: 200 }]}>
              {item.detalle}
            </Text>
            {/*
            <Text
              style={[
                checkingStyles.cell,
                {
                  width: 200,
                  color: item.detalle.includes('Pendiente') ? 'red' : 'green',
                  fontWeight: 'bold',
                },
              ]}
            >
              {item.detalle.includes('Pendiente') ? 'No pagado' : 'Pagado'}
            </Text>
            */}
              <Text onPress={() => onLinkClick('https://pagos.defensadeudores.cl', true)}
                style={navigationStyles.buttonActiveLink} >
                <Text style={navigationStyles.buttonActiveText}>Portal de pagos</Text>
              </Text>
            {/*
            item.detalle.includes('Pendiente') ? 
              <Text onPress={() => onLinkClick('https://pagos.defensadeudores.cl', true)}
                style={navigationStyles.buttonActiveLink} >
                <Text style={navigationStyles.buttonActiveText}>Ir a pagar</Text>
              </Text> :
              <Text style={navigationStyles.buttonNoActiveLink} >
              <Text style={navigationStyles.buttonActiveText}>Portal de pagos</Text>
            </Text>
            */}

            {/* <View
              style={[
                checkingStyles.cell,
                {
                  width: 200,
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'row',
                },
              ]}
            >
              <TouchableOpacity
                style={[
                  checkingStyles.paymentOption,
                  item.pagar === 'option1' &&
                    checkingStyles.selectedPaymentOption,
                ]}
                onPress={() => handlePaymentOptionSelection('option1', index)}
              ></TouchableOpacity>
            </View> */}
          </View>
        ))
      ) : (
        null
      )}
    </View>
  )
}

const stylesButton = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    paddingVertical: 5,
    paddingHorizontal: 20,
    borderRadius: 5,
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  line: {
    height: 1,
    backgroundColor: 'gray', // Color de la línea
    marginTop: 8, // Espacio superior para separar la línea del contenido
  }
});

export default withRouter(CheckingAccount)
