import React, { useEffect, useState } from 'react'
import { Text, View, TouchableOpacity, StyleSheet } from 'react-native'
import HeaderPageTitle from '../../common/HeaderPageTitle'
import { generalStyles } from '../../styles/general.styles'
import { paymentStyles } from './payment.styles'
import { cuotasClienteQuery } from '../../services/axios'
import Loading from '../../common/Loading'
import { withRouter } from 'react-router-dom'
import {format} from 'date-fns'

const PaymentPortal = ({ history }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      history.push('/iniciar-sesion') // Ajusta la ruta de inicio de sesión según tu configuración de navegación
    }
  }, [])

  useEffect(() => {
    const getData = async () => {
      try {
        
        const response = await cuotasClienteQuery(
          '/Client/CuotasCliente',
          localStorage.getItem('token')
        )
         console.log(response) 
        const filteredData = response.data[1].filter((item) =>
          item.detalle.includes('(No Pago)')
        )
        console.log(filteredData)
        setData(filteredData)
      } catch (error) {
        console.log(error)
      }
    }
    getData()
  }, [])

  useEffect(()=>{
    console.log(data)
  },[data])

  //console.log(data)

  const [selectedPaymentOption, setSelectedPaymentOption] = useState('')

  const handlePaymentOptionSelection = (option, index) => {
    const newData = [...data]
    newData[index].pagar = newData[index].pagar === option ? '' : option
    setSelectedPaymentOption(newData[index].pagar)
  }

  return (
    <View style={generalStyles.contentContainer}>
      <HeaderPageTitle title="Portal de pagos 1" />
      <View style={paymentStyles.row}>
        <Text style={[paymentStyles.headerCell, { width: 200 }]}>Nombre</Text>
        <Text style={[paymentStyles.headerCell, { width: 200 }]}>Monto</Text>
        <Text style={[paymentStyles.headerCell, { width: 200 }]}>Vencimiento</Text>
        <Text style={[paymentStyles.headerCell, { width: 200 }]}>Estado</Text>
        <Text style={[paymentStyles.headerCell, { width: 200 }]}>Pagar</Text>
      </View>
      {data ? (
        data.map((item, index) => (
          <View style={paymentStyles.row} key={index}>
            <Text style={[paymentStyles.cell, { width: 200 }]}>
              {item.empresa}
            </Text>
            <Text style={[paymentStyles.cell, { width: 200 }]}>
              {item.monto}
            </Text>
            <Text style={[paymentStyles.cell, { width: 200 }]}>
              {format(new Date(item.vencimiento), 'dd/MM/yyyy')}
            </Text>
            <Text style={[paymentStyles.cell, { width: 200 }]}>
              {item.glosa}
            </Text>
            <View
              style={[
                paymentStyles.cell,
                {
                  width: 200,
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'row',
                },
              ]}
            >
              <TouchableOpacity
                style={[
                  paymentStyles.paymentOption,
                  item.pagar === 'option1' &&
                    paymentStyles.selectedPaymentOption,
                ]}
                onPress={() => handlePaymentOptionSelection('option1', index)}
              ></TouchableOpacity>
            </View>
          </View>
        ))
      ) : (
        <Loading />
      )}
    </View>
  )
}

export default withRouter(PaymentPortal)
