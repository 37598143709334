import { StyleSheet } from 'react-native'

export const appointmentConfirmationStyles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    padding: 16,
    borderRadius: 8,
    marginVertical: 16,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    margin: 19,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 15,
    color: '#0076b4',
  },
  info: {
    marginBottom: 10,
  },
  boldText: {
    fontWeight: 'bold',
    color: 'green',
  },
  cancelButton: {
    backgroundColor: '#cc0000',
    padding: 12,
    borderRadius: 8,
    marginTop: 16,
  },
  closeButton: {
    backgroundColor: '#909090',
    padding: 12,
    borderRadius: 8,
    marginTop: 16,
  },
  cancelButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  closeButtonText:{
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
})
